import { Script } from "gatsby";
import * as React from "react";
import { PropsWithChildren } from "react";

import { address, contact } from "../../../data/config";

interface PropsMarkupService extends PropsWithChildren<any> {
  name: string;
  slug: string;
  image: string;
}

const ServiceMarkupSchema: React.FC<PropsMarkupService> = (
  props: PropsMarkupService
) => {
  const markup = `{
  "@context": "https://schema.org",
  "@type": "ProfessionalService",
  "name": "${props.name}",
  "image": "${props.image}",
  "url": "https://www.cyrseguridad.com.ar/${props.slug}/",
  "email": "${contact.email}",
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "${address.streetAddress}",
    "addressLocality": "${address.city}",
    "postalCode": "${address.zipCode}",
    "addressCountry": "AR"
  }
}
`;
  return (
    <Script strategy={"post-hydrate"} type="application/ld+json">
      {markup}
    </Script>
  );
};

export default ServiceMarkupSchema;

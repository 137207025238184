import Layout from "components/common/layout/layout";
import { SEO } from "components/common/SEO";
import { graphql } from "gatsby";
import React from "react";

import ActionRatio from "../components/actionRatio/ActionRatio";
import Bonus from "../components/bonus/Bonus";
import ServiceMarkupSchema from "../components/common/SEO/ServiceMarkupSchema";
import Corporate from "../components/corporate/corporate";
import Gallery from "../components/gallery/Gallery";
import HeroPage from "../components/heroPage/HeroPage";
import SliderSegments from "../components/sliderSegments/SliderSegments";

const PageService = ({ data }) => {
  const services = data.sanityService;
  return (
    <Layout>
      <section className={"is-z1"}>
        <HeroPage hero={services.hero} />
      </section>
      <section className={"is-z2"}>
        {services.title &&
          services.desc &&
          services.bonus &&
          services.bonus.title && (
            <Bonus
              title={services.title}
              desc={services.desc}
              goals={services.bonus}
            />
          )}
      </section>
      <section className={"is-relative"}>
        <div className={"a-radar a-radar--left"}></div>
      </section>
      {services.slogan && services.features && (
        <Corporate slogan={services.slogan} features={services.features} />
      )}

      {services.gallery && <Gallery photos={services.gallery} />}
      {services.segments && <SliderSegments segments={services.segments} />}
      <ActionRatio />
    </Layout>
  );
};

export default PageService;

export const Head = ({ data }) => (
  <>
    <SEO
      title={`${data.sanityService.seo.title.es_AR}`}
      description={data.sanityService.seo.desc.es_AR}
      robots="index, follow"
      canonical={`${data.sanityService.slug.es_AR}/`}
    />
    <ServiceMarkupSchema
      name={data.sanityService.seo.title.es_AR}
      slug={data.sanityService.slug.es_AR}
      image={
        data.sanityService.hero.img.asset.gatsbyImageData.images.fallback.src
      }
    />
  </>
);

export const query = graphql`
  query CardSegmentsinService(
    $id: String = "-3870a923-a308-5725-a049-cab27f45d756"
  ) {
    sanityService(id: { eq: $id }) {
      slug {
        es_AR
        es_AR
      }
      segments {
        has_ready
        card {
          img {
            asset {
              gatsbyImageData
              id
              _id
            }
          }
          title {
            es_AR {
              children {
                text
              }
            }
            es_AR {
              children {
                text
              }
            }
          }
        }
        slug {
          es_AR
          es_AR
        }
      }
      hero {
        img {
          _key
          _type
          asset {
            _id
            id
            gatsbyImageData(
              width: 1197
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        desc {
          _rawEsAr
          es_AR {
            children {
              text
            }
          }
        }
        alt {
          es_AR
          es_AR
        }
        title {
          _rawEsAr
          es_AR {
            children {
              text
            }
          }
        }
      }
      title {
        es_AR
        es_AR
      }
      desc {
        _rawEsAr
        es_AR {
          children {
            text
          }
        }
      }
      bonus {
        title {
          es_AR
          es_AR
        }
        items {
          es_AR
          es_AR
        }
      }
      slogan {
        title {
          _rawEsAr
          es_AR {
            children {
              text
            }
          }
        }
        desc {
          _rawEsAr
          es_AR {
            children {
              text
            }
          }
        }
      }
      features {
        img {
          asset {
            gatsbyImageData
            id
            _id
          }
        }
        alt {
          es_AR
          es_AR
        }
        desc {
          es_AR
          es_AR
        }
        title {
          es_AR
          es_AR
        }
      }
      gallery {
        asset {
          gatsbyImageData
          id
          _id
        }
      }
      seo {
        title {
          es_AR
          es_AR
        }
        desc {
          es_AR
          es_AR
        }
        canonical {
          es_AR
          es_AR
        }
        robots
      }
    }
  }
`;
